import * as React from 'react';
import type {SlotProps} from '@radix-ui/react-slot';
import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import {Link} from '@remix-run/react';
import type {RemixLinkProps} from '@remix-run/react/dist/components';

import {cn} from '~/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-silver/30 hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        linkCoolBlue:
          'text-cool_blue underline-offset-4 hover:underline hover:text-cool_blue/80',
        inline: '',
        cool: 'bg-cool_blue text-primary-foreground hover:bg-cool_blue/80',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
      width: {
        full: 'w-full',
        auto: 'w-auto',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  as?: React.ElementType;
}

export interface LinkProps
  extends React.ButtonHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  to?: string;
}

// Type guard to check if props is of type LinkProps
function isLinkProps(props: ButtonProps | LinkProps): props is LinkProps {
  return 'to' in props;
}

// Type guard to check if props is of type LinkProps
function isButtonProps(props: ButtonProps | LinkProps): props is ButtonProps {
  return true;
}

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps | LinkProps
>(({className, variant, size, width, asChild = false, ...props}, ref) => {
  let Comp:
    | string
    | React.ForwardRefExoticComponent<
        RemixLinkProps & React.RefAttributes<HTMLAnchorElement>
      >
    | React.ForwardRefExoticComponent<
        SlotProps & React.RefAttributes<HTMLElement>
      > = asChild ? Slot : 'button';
  if (isLinkProps(props)) {
    const {to} = props;
    Comp = Link;
    const linkRef = ref as React.Ref<HTMLAnchorElement>;
    const variants = buttonVariants({variant, size, width, className});
    return (
      <Comp className={cn(variants)} ref={linkRef} {...props} to={to || ''} />
    );
  }

  if (isButtonProps(props)) {
    const {as} = props;
    Comp = (as as string) || 'button';
  }

  return (
    <Comp
      className={cn(buttonVariants({variant, size, width, className}))}
      ref={ref}
      {...props}
    />
  );
});
Button.displayName = 'Button';

export {Button, buttonVariants as ButtonVariants};
